import CryptoJS from 'crypto-js';
import { stringify } from 'query-string';

const appKey = '03F223F5730A4D38817D56E334396B08';
const appId = 'APP6967CDB035FD41B99A087118366C7DC3';

interface JsonDataTS {
  url: string | undefined;
  method: string | undefined;
  query?: Record<string, any>;
  params: Record<string, any>;
}

/**
 * 对象升序处理
 */
const sortString = (data: Record<string, any>) => {
  const keys = Object.keys(data).sort();
  const params: Record<string, any> = {};
  keys.forEach((key) => {
    params[key] = data[key];
  });
  return params;
};

const signature = (jsonData: JsonDataTS) => {
  const api = jsonData.url,
    requestData = JSON.stringify(jsonData.params),
    params = jsonData.query ? stringify(sortString(jsonData.query)) : '', // params 也要做升序排序
    httpType = jsonData.method?.toUpperCase() || 'POST', // 需要大写
    app_key = appKey,
    appid = appId,
    timeStamp = new Date().getTime(),
    headers = sortString({
      // 升序排列 ，不可乱
      'Content-Type': 'application/json',
      'X-Appid': appid,
      'X-Deviceid': 'icp_h5',
      'X-Timestamp': timeStamp,
    });
  const body_hash = CryptoJS.SHA256(requestData).toString();

  // 数组顺序一定不能乱
  const _requestData = [httpType, api, params, headers, body_hash];
  const data = _requestData
    .map((item) => {
      if (typeof item === 'object') {
        // 只有headers是对象
        const arr = Object.keys(item);
        const c_headers = arr.map((v) => `${v.toLowerCase()}:${item[v]}`);
        return c_headers.join('\n') + '\n' + arr.join(';').toLowerCase();
      } else {
        return item;
      }
    })
    .join('\n');

  const hash_data = CryptoJS.SHA256(data).toString();
  const hmac_data = CryptoJS.HmacSHA256(hash_data, app_key).toString();

  headers['Authorization'] = hmac_data;

  return headers;
};

export default signature;
