// 保存文件信息
import { createSlice } from '@reduxjs/toolkit';

type IState = Record<string, any>;

const initialState: IState = {};

const file = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFile: (state, action) => {
      const { payload } = action;
      return payload;
    },
  },
});

export const getFiles = (state: { file: IState }) => state?.file;

export const { setFile } = file?.actions;

export default file.reducer;
