import React, { Suspense, lazy } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Loading } from 'src/components';
import history from 'src/utils/history';
import routes from './router';

function Routes() {
  return (
    <Router history={history}>
      <Suspense fallback={<Loading visible />}>
        <Switch>
          {/* <Redirect exact from="/" to="/record-management" /> */}
          {routes.map((item) => (
            <Route
              exact
              key={item.path}
              path={item.path}
              component={item.components}
            />
          ))}
          <Route component={lazy(() => import('./no-match/NoMatch'))} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
