// 获取验证码信息
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = Record<string, any>;

const initialState: IState = {};

const captcha = createSlice({
  name: 'form',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<IState>) => {
      return action?.payload;
    },
    remove: () => {
      return {};
    },
  },
});

export const getCaptcha = (state: { captcha: IState }) => state?.captcha;

export const { add, remove } = captcha?.actions;

export default captcha.reducer;
