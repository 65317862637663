import React from 'react';
import { ReactSVG } from 'react-svg';
import styles from './styles.module.scss';

//  如何添加一个新的icon =》 在svg文件夹中添加新的svg文件，文件名就是name。 如果要改变颜色，需要删除文件中的fill属性

interface IIcon {
  color?: string;
  fontSize?: number;
  name: string;
  onClick?: (e: any) => void;
  className?: string;
}

const getAllIcons = (context: any) => {
  return context.keys().reduce((icons: any, modulePath: any, index: number) => {
    icons[modulePath.replace(/.\/|.svg/g, '')] = (props: any) => {
      return (
        <ReactSVG src={context.keys().map(context)[index].default} {...props} />
      );
    };
    return icons;
  }, {});
};

const empty = () => <div />;

const icons = getAllIcons(require.context('../../svg', false, /\.svg$/));

const Icon = ({ name, color = '#333', fontSize = 14, ...props }: IIcon) => {
  const SvgIcon = icons[name] || empty;
  return (
    <SvgIcon
      {...props}
      className={[styles.icon]}
      style={{ color: color, fontSize: `${fontSize / 37.5}rem` }}
    />
  );
};

export default React.memo(Icon);
