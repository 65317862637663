import React from 'react';
import Icon from '../icon/Icon';
import styles from './styles.module.scss';

const Empty = () => (
  <div className={styles.message}>
    <Icon name="message" color="#FF742F" />
    <span>您暂无需要电子化核验的ICP备案。</span>
    <span>请在Web端完成备案基础信息的填写并根据指引进入页面</span>
  </div>
);

export default Empty;
