export const createStorage = (name: string) => {
  const storage = window.sessionStorage;
  return {
    set: (params: object | string | number | boolean) => {
      storage.setItem(name, JSON.stringify(params));
    },
    remove: () => {
      storage.removeItem(name);
    },
    get: () => {
      return JSON.parse(storage.getItem(name) || '{}');
    },
  };
};

export const authStorage = createStorage('auth');

export const urlParamsStorage = createStorage('urlParams');

export const cloudProtocolStorage = createStorage('cloudProtocol');
