// 保存登陆数据
import { createSlice } from '@reduxjs/toolkit';
import { authStorage } from 'src/utils/storage';

type IState = Record<string, any>;

const initialState: IState = {};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: () => {
      const auth = authStorage.get() || {};
      return auth;
    },
    setAuth: (state, action) => {
      const { payload } = action;
      state = { ...state, ...payload, token: payload?.data?.token };
      authStorage.set(state);
      return state;
    },
    removeAuth: () => {
      authStorage.remove();
      return {};
    },
  },
});

export const getAuth = (state: { auth: IState }) => state?.auth;

export const { initAuth, setAuth, removeAuth } = auth?.actions;

export default auth.reducer;
