// 全局的loading
import React from 'react';
import { Loading as ALoading } from 'antd-mobile';
import styles from './styles.module.scss';

interface ILoading {
  visible: boolean;
}

const Loading = ({ visible }: ILoading) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.loading}>
      <span>加载中</span>
      <ALoading />
    </div>
  );
};

export default Loading;
