// 保存销毁form 表单的数据
import { createSlice } from '@reduxjs/toolkit';
import { urlParamsStorage } from 'src/utils/storage';
import { getParams } from 'src/utils/url';

type IState = Record<string, any>;

const initialState: IState = {};

const urlParams = createSlice({
  name: 'urlParams',
  initialState,
  reducers: {
    initUrlParams: () => {
      let urlParams = urlParamsStorage.get();
      if (Object.keys(urlParams).length === 0) {
        urlParams = getParams();
      }
      return urlParams;
    },
    setUrlParams: () => {
      const params = getParams();
      urlParamsStorage.set(params);
      return params;
    },
    removeUrlParams: () => {
      urlParamsStorage.remove();
      return {};
    },
  },
});

export const getUrlParams = (state: { urlParams: IState }) => state?.urlParams;

export const {
  initUrlParams,
  setUrlParams,
  removeUrlParams,
} = urlParams?.actions;

export default urlParams.reducer;
