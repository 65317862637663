import { lazy } from 'react';

const recordManagement = lazy(
  () => import('./record-management/RecordManagementPage')
);
const subjectInfoVerify = lazy(
  () => import('./subject-info-verify/SubjectInfoVerifyPage')
);
const networkInfoVerify = lazy(
  () => import('./network-info-verify/NetworkInfoVerifyPage')
);
const authenticityCheck = lazy(() => import('./authenticity-check'));
const livenessAgreement = lazy(
  () => import('./authenticity-check/AgreementPage')
);
const livenessDetection = lazy(
  () => import('./authenticity-check/DetectionPage')
);
const livenessVerify = lazy(() => import('./authenticity-check/VerifyPage'));
const livenessFail = lazy(() => import('./authenticity-check/FailPage'));
const livenessSuccess = lazy(() => import('./authenticity-check/SuccessPage'));
const protocolFile = lazy(() => import('./protocol-file/ProtocolFilePage'));

export const ROUTE_PATH = {
  RECORD_MANAGEMENT: '/',
  SUBJECT_INFO_VERIFY: '/subject-info-verify',
  NETWORK_INFO_VERIFY: '/network-info-verify',
  AUTHENTICITY_CHECK: '/authenticity-check',
  LIVENESS_AGREEMENT: '/liveness-agreement',
  LIVENESS_DETECTION: '/liveness-detection',
  LIVENESS_VERIFY: '/liveness-verify',
  LIVENESS_FAIL: '/liveness-fail',
  LIVENESS_SUCCESS: '/liveness-Success',
  PROTOCOL_FILE: '/protocolFile',
};

const routes = [
  {
    path: ROUTE_PATH.RECORD_MANAGEMENT,
    name: '备案管理',
    components: recordManagement,
  },
  {
    path: ROUTE_PATH.SUBJECT_INFO_VERIFY,
    name: '主体信息核验',
    components: subjectInfoVerify,
  },
  {
    path: ROUTE_PATH.NETWORK_INFO_VERIFY,
    name: '网站信息核验',
    components: networkInfoVerify,
  },
  {
    path: ROUTE_PATH.AUTHENTICITY_CHECK,
    name: '真实性核验',
    components: authenticityCheck,
  },
  {
    path: ROUTE_PATH.LIVENESS_AGREEMENT,
    name: '用户协议',
    components: livenessAgreement,
  },
  {
    path: ROUTE_PATH.LIVENESS_DETECTION,
    name: '人脸识别-视频采集',
    components: livenessDetection,
  },
  {
    path: ROUTE_PATH.LIVENESS_VERIFY,
    name: '人脸识别-视频验证',
    components: livenessVerify,
  },
  {
    path: ROUTE_PATH.LIVENESS_FAIL,
    name: '人脸识别-错误提示',
    components: livenessFail,
  },
  {
    path: ROUTE_PATH.LIVENESS_SUCCESS,
    name: '人脸识别-成功页面',
    components: livenessSuccess,
  },
  {
    path: ROUTE_PATH.PROTOCOL_FILE,
    name: '协议文件详情',
    components: protocolFile,
  },
];

export default routes;
