// 扫码认证
import { Toast } from 'antd-mobile';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Empty } from 'src/components';
import { getAuth, initAuth, setAuth, removeAuth } from 'src/store/reducer/auth';
import { add } from 'src/store/reducer/captcha';
import {
  getCloudProtocol,
  initCloudProtocol,
} from 'src/store/reducer/cloudProtocol';
import {
  getUrlParams,
  initUrlParams,
  setUrlParams,
} from 'src/store/reducer/urlParams';
import { getParams } from 'src/utils/url';
import { authentication, captcha } from './api';
import Captcha from './Captcha';
import Slider from './Slider';
import CloudServiceProtocol from './CloudServiceProtocol';
import styles from './styles.module.scss';

interface IAuth {
  children: ReactElement;
}

const Auth = (props: IAuth) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { token, code, message, isDone } = useSelector(getAuth);
  const { authToken } = useSelector(getUrlParams);
  const cloudProtocol_isCheck = useSelector(getCloudProtocol);
  const { authToken: currentAuthToken } = getParams();

  const login = useCallback(
    ({
      captchaUuid,
      captchaCode,
    }: {
      captchaUuid: string;
      captchaCode?: string;
    }) => {
      setIsLoading(true);
      authentication({ captchaUuid, captchaCode }).then((res: any) => {
        setIsLoading(false);
        dispatch(setAuth(res));
        dispatch(setUrlParams());
        if (res.code !== 2000 && res.message && res.code !== 4045) {
          Toast.show(res.message);
        }
        //  二维码失效重新请求二维码
        if (res.code === 4033 || res.code === 4045) {
          captcha().then((res) => {
            dispatch(add(res));
          });
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // 路由不一致的时候要重新请求一下
    dispatch(initAuth());
    dispatch(initUrlParams());
    dispatch(initCloudProtocol());
  }, [dispatch, token, login, authToken, currentAuthToken]);

  if (code === 5999 || isDone) {
    return <Empty />;
  }

  if (code === 5118) {
    dispatch(removeAuth());
    return (
      <div className={styles.message}>
        <Icon name="message" color="#FF742F" />
        <span>{message}</span>
      </div>
    );
  }
  if (!token) {
    // return <Captcha onLogin={login} isLoading={isLoading} />;
    return <Slider onLogin={login} isLoading={isLoading} />;
  }

  // 判断是不是有同意协议, 没有的话展示同意协议的页面
  if (!cloudProtocol_isCheck) {
    return <CloudServiceProtocol />;
  }

  return props.children;
};

export default Auth;
