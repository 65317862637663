// 公共api
import api from 'src/utils/api';
import { IUpload } from './type';

export const upload = (params: IUpload) => {
  const formData = new FormData();
  formData.append('icprMainId', params.icprMainId.toString());
  params.file && formData.append('file', params.file);
  params.certName && formData.append('certName', params.certName);
  params.comCertNo && formData.append('comCertNo', params.comCertNo);
  params.type && formData.append('type', params.type.toString());
  params.certNo && formData.append('certNo', params.certNo);
  params.icprWebsiteId &&
    formData.append('icprWebsiteId', params.icprWebsiteId);
  params.comName && formData.append('comName', params.comName);

  return api
    .post('/cloudH5/upload', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res: any) => {
      return res;
    });
};

export const deleteFile = ({ fileKey }: { fileKey: string }) => {
  return api.post('/cloudH5/deleteFile', { fileKey });
};
