const category = window.navigator.userAgent;

export const isWeiXin = () => {
  var ua = category.toLowerCase();
  if (ua.indexOf('micromessenger') !== -1) {
    return true;
  } else {
    return false;
  }
};

export const isAndroid =
  category.indexOf('Android') > -1 || category.indexOf('Adr') > -1; //android终端
export const isiOS = !!category.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
