// 保存同意平安云协议
import { createSlice } from '@reduxjs/toolkit';
import { cloudProtocolStorage } from 'src/utils/storage';

type IState = Record<string, boolean>;

const initialState: IState = { isCheck: false };

const cloudProtocol = createSlice({
  name: 'cloudProtocol',
  initialState,
  reducers: {
    initCloudProtocol: () => {
      const protocol = cloudProtocolStorage.get();
      return { isCheck: protocol?.isCheck };
    },
    setCloudProtocol: () => {
      cloudProtocolStorage.set({ isCheck: true });
      return { isCheck: true };
    },
  },
});

export const getCloudProtocol = (state: { cloudProtocol: IState }) =>
  state?.cloudProtocol?.isCheck;

export const { initCloudProtocol, setCloudProtocol } = cloudProtocol?.actions;

export default cloudProtocol.reducer;
