import React, { useEffect } from 'react';
import getChallenge from './greetestFunc';
import styles from './styles.module.scss';
interface ICaptcha {
  onLogin: ({
    captchaUuid,
    captchaCode,
  }: {
    captchaUuid: string;
    captchaCode?: string;
  }) => void;
  isLoading: boolean;
}

const Captcha = ({ onLogin }: ICaptcha) => {
  useEffect(() => {
    getChallenge(document.getElementsByClassName(styles.captcha), (data:any) => {
      onLogin(data);
    })
  }, []);

  return (
    <div className={styles.captcha}></div>
  );
};

export default Captcha;
