import React from 'react';
import Input from './Input';
import { IInput } from './type';

interface IInputItem extends IInput {
  title: string;
  required?: boolean;
}

const InputItem = React.forwardRef<HTMLInputElement, IInputItem>(
  ({ title, required, ...props }, ref) => {
    return (
      <div className="component-item">
        <span className="component-item-title">
          {title}
          {required && <span className="component-required">*</span>}
        </span>
        <Input {...props} />
      </div>
    );
  }
);

export default React.memo(InputItem);
