// 保存销毁form 表单的数据
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = Record<string, object>;

interface IName {
  name: string;
}

interface IFormAction extends IName {
  formData: object;
}

const initialState: IState = {};

const form = createSlice({
  name: 'form',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<IFormAction>) => {
      const { name, formData } = action?.payload;
      state[name] = formData;
      return state;
    },
    remove: (state, action: PayloadAction<IName>) => {
      const { name } = action?.payload;
      delete state[name];
      return state;
    },
  },
});

export const getForm = (state: { form: IState }) => state?.form;

export const { add, remove } = form?.actions;

export default form.reducer;
