// 获取链接上的参数

export const getParams = () => {
  var url = window.location.search;
  var params: Record<string, string> = {};
  if (url.indexOf('?') !== -1) {
    var str = url.substr(1);
    var strs = str.split('&');
    for (var i = 0; i < strs.length; i++) {
      params[strs[i].split('=')[0]] = decodeURIComponent(
        decodeURIComponent(strs[i].split('=')[1].replace(/%/g, '%25'))
      );
    }
  }

  return params;
};

export const getParam = (param: string) => {
  var params = getParams();
  return params[param];
};
