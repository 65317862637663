import React, { useEffect } from 'react';
import { Checkbox } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import { getFile } from 'src/api/file/file';
import { setFile } from 'src/store/reducer/file';
import styles from './styles.module.scss';
import { goProtocolFile } from 'src/pages/protocol-file/navigator';

interface IProtocol {
  left?: string;
  list: Array<{
    title: string;
    unlink?: boolean;
    name: string;
    headerMap: string;
    onClick?: (e: any) => void;
  }>;
  right?: string;
  disabled?: boolean;
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
  onDisableHint?: () => void;
}

const Protocol = ({
  list,
  left,
  right,
  disabled,
  onDisableHint,
  isChecked,
  setIsChecked,
}: IProtocol) => {
  const dispatch = useDispatch();

  // 先请求接口
  useEffect(() => {
    //  返回跳转的链接
    getFile().then((res) => {
      dispatch(setFile(res));
    });
  }, [dispatch]);

  return (
    <div className={styles.protocol}>
      <Checkbox
        block={false}
        data-seed="agree"
        checked={isChecked}
        onChange={() => {
          if (disabled) {
            onDisableHint && onDisableHint();
          } else {
            setIsChecked(!isChecked);
          }
        }}
      >
        {left}
        {list.map((item, index) => (
          <span
            onClick={(e) => {
              if (item.onClick) {
                item.onClick(e);
              } else if (!item.unlink) {
                goProtocolFile({ title: item.title, name: item.name, headerMap: item.headerMap });
              }
              e.preventDefault();
            }}
            key={item.title}
          >
            《{item.title}》{index !== list.length - 1 && '、'}
          </span>
        ))}
        {right}
      </Checkbox>
    </div>
  );
};

export default React.memo(Protocol);
