import api from 'src/utils/api';
import { getParams } from 'src/utils/url';

export const captcha = () => {
  return api.post('/cloudH5/captchaImage').then((res) => {
    return res.data;
  });
};

export const authentication = ({
  captchaUuid,
  captchaCode,
}: {
  captchaUuid: string;
  captchaCode?: string;
}) => {
  const { authToken, icprMainId, qrCodeKey, env } = getParams();
  return api.post('/cloudH5/authentication', {
    authToken,
    icprMainId,
    qrCodeKey,
    env,
    captchaUuid,
    captchaCode,
  });
};


export const logout = () => {
  return api.post('/cloudH5/logout').then((res) => {
    return res.data;
  });
};