import React from 'react';
import styles from './styles.module.scss';

const ErrorMessage = ({ message }: { message: string | undefined }) => {
  const error = message?.split('-') || [];
  // 接口错误
  if (error[0] === 'apiError') {
    return (
      <span className={styles.errorMessage}>
        {error[1] && `！核验不通过。不通过原因：${error[1]}`}
      </span>
    );
  } else {
    return <span className={styles.errorMessage}>{message}</span>;
  }
};

export default React.memo(ErrorMessage);
