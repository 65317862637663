import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Button, Protocol, Loading, Spacing } from 'src/components';
import { getFiles } from 'src/store/reducer/file';
import { useSelector } from 'react-redux';
import { setCloudProtocol } from 'src/store/reducer/cloudProtocol';
import { getFileDetail } from 'src/api/file/file';
import styles from './styles.module.scss';
import { CLOUD_SERVICE_PROTOCOL_URL, CLOUD_SERVICE_PROTOCOL_HEADER_MAP } from './constants';

const CloudServiceProtocol = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const files = useSelector(getFiles);

  const dispatch = useDispatch();

  useEffect(() => {
    //  获取协议内容
    const file = files[CLOUD_SERVICE_PROTOCOL_URL];
    const headerMap = files[CLOUD_SERVICE_PROTOCOL_HEADER_MAP];
    setIsLoading(true);
    if (file) {
      getFileDetail(file, headerMap).then((res) => {
        setData(decodeURIComponent(res.replace(/%/g, '%25') || ''));
        setIsLoading(false);
      });
    }
  }, [files]);

  const onCommit = useCallback(() => {
    dispatch(setCloudProtocol());
  }, [dispatch]);

  return (
    <>
      <Header title="平安云ICP备案" hideIcon />
      <Loading visible={isLoading} />
      <div
        className={styles.file}
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
      <div className={styles.protocol}>
        <Protocol
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          list={[
            {
              title: '平安云ICP备案产品及服务使用协议',
              name: CLOUD_SERVICE_PROTOCOL_URL,
              headerMap: CLOUD_SERVICE_PROTOCOL_HEADER_MAP,
              unlink: true,
            },
          ]}
          left="我已认真阅读并同意"
        />
      </div>
      <Spacing height={12} />
      <Button title="开始备案" disabled={!isChecked} onClick={onCommit} />
      <Spacing height={12} />
    </>
  );
};

export default React.memo(CloudServiceProtocol);
