// @ts-nocheck
import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import { ImageUploader, Mask, Toast } from 'antd-mobile';
import { useController } from 'react-hook-form';
import ErrorMessage from 'src/components/error-message/ErrorMessage';
import Icon from 'src/components/icon/Icon';
import { deleteFile, upload } from 'src/api/upload/upload';
import styles from './styles.module.scss';
import { IImageUpload } from './type';
import axios from 'axios';
const beforeUpload = (disabled?: boolean) => (files: File[]) => {
  return files.filter((file) => {
    if (disabled) {
      Toast.show({ content: '缺少关键信息填写', icon: 'fail' });
      return false;
    }
    if (file.size > 1024 * 1024 * 10) {
      Toast.show('您上传的图片过大');
      return false;
    }
    return true;
  });
};


const ImageUpload = React.forwardRef<HTMLImageElement, IImageUpload>(
  (
    {
      name,
      onChange,
      maxCount,
      setValue,
      disabled,
      params,
      setError,
      ...props
    },
    ref
  ) => {
    const { field, fieldState } = useController({ ...props, name });
    const [loading, setLoading] = useState(false);
    const [randomKey, setRandomKey] = useState(1);
    
    const { ref: imgRef, ...rest } = field;
    const keyObj = useRef<any>({});

    // 将value赋值给另外一个变量
    const [urlList, setUrlList] = useState({value: undefined});
    
    const max = useMemo(() => {
      let count = 1;
      let len = field.value?.length || 0;
      const max = maxCount || 1;
      if (len < max) {
        count = len + 1;
      } else {
        count = max;
      }
      return count;
    }, [maxCount, field.value]);

    const onDelete = useCallback(
      (file: any) => {
        const fileKey = file?.fileKey || keyObj.current[file.url];
        if (fileKey) {
          deleteFile({ fileKey }).then((res: any) => {
            if (res?.code === 2000) {
              Toast.show('删除成功');
              // 删除文件
              let list = urlList.value;
              const listIndex = list?.findIndex(item=>item.fileKey == fileKey);
              list?.splice(listIndex, 1)
              
              setUrlList({value: list});
              setRandomKey(Math.random());
            }
          });
        }
        return true;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    const getUrl = ({url, headerMap}, index) => {
      return new Promise((resolve, reject)=>{
        axios.get(url, {
          responseType: 'blob',
          headers: {
            ...headerMap
          }
        }).then((res) => {
          let src: string = '';
          try {
            src = URL.createObjectURL(res.data);
          } catch(e) {
            console.error(e);
          }
          resolve(src)
        })
      })
    }
    const getSrc =async ()=>{
      if(rest.value?.length) {
        let getUrlList = [];
        const list = rest.value?.map(item=>getUrl(item));
        const url = await Promise.all(list);
        getUrlList =  rest?.value?.map((item, index)=>{
          return {...item, url: url[index]}
        })
        if(getUrlList?.length) {
          setUrlList({value:getUrlList});
        }else {
          setUrlList({value: undefined});
        }
        
        // setUrlList(urlList?.map((item, index)=>{
        //   return {...item, imgUrl: url[index]}
        // }));
      }
    }
    useEffect(()=>{
      getSrc();
    }, [rest.value?.length])
   
    
    return (
      <>
        <div className={styles.imageUpload}>
          {/* 上传前的样式 */}
          {(field?.value?.length === 0 || !field.value || !urlList.value) && (
            <div className={styles.content}>
              <div className={styles.icon}>
                <Icon name="camera" />
              </div>
              <span>
                请上传彩色清晰的<span className={styles.strong}>正向</span>照片
              </span>
              <span>大小不要超过10M</span>
            </div>
          )}
          <ImageUploader
            key={randomKey}
            beforeUpload={beforeUpload(disabled)}
            {...urlList}
            maxCount={max}
            upload={async (file: File) => {
              const url = URL.createObjectURL(file);
              setLoading(true);
              Toast.show({
                content: '上传中',
                icon: 'loading',
                duration: 0,
              });
              await upload({ ...params, file }).then((res) => {
                setLoading(false);
                Toast.clear();
                // 更新供删除
                if (res.code === 2000) {
                  Toast.show('上传成功');
                  keyObj.current[url] = res.data.fileKey;
                } else {
                  setError &&
                    setError(name, {
                      type: 'manual',
                      message: `apiError-${res.message}`,
                    });
                  throw new Error('上传错误');
                }
              }).catch(err => {
                setLoading(false);
                Toast.clear();
                if(err.message.includes('timeout')){
                  setError &&
                    setError(name, {
                      type: 'manual',
                      message: 'apiError-网络超时',
                    });
                }
                throw new Error('上传错误');
              });
              return {
                url,
              };
            }}
            accept="image/*"
            onDelete={onDelete}
          />
          
            {/* <div  className='adm-image-uploader' style={{width: '100%', position:'absolute', top: 0}}>
              <div className="adm-space adm-space-wrap adm-space-horizontal adm-image-uploader-space">
              {
                urlList?.map((item:any, index:number)=>{
                  return <div key={index} className="adm-space-item">
                    <div className="adm-image-uploader-cell">
                      <div className="adm-image adm-image-uploader-cell-image">
                        <img className="adm-image-img" alt="" src={item.imgUrl} />
                      </div>
                      <div className="adm-image-uploader-cell-delete" onClick={()=>{onDelete(item)}}>
                        <Icon name="imgDelete" color='#ffffff' />
                      </div>
                    </div>
                  </div>
                })
              }
              </div>
            </div> */}
          <ErrorMessage message={fieldState?.error?.message} />
        </div>
        <Mask visible={loading} destroyOnClose opacity={0}></Mask>
      </>
    );
  }
);

export default React.memo(ImageUpload);
