import React from 'react';
import { History } from 'history';
import { Button as AButton } from 'antd-mobile';
import styles from './styles.module.scss';

interface IButton {
  title: string;
  history?: History;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = ({ title, disabled, onClick }: IButton) => {
  return (
    <div className={styles.button}>
      <AButton color="primary" disabled={disabled} onClick={onClick}>
        {title}
      </AButton>
    </div>
  );
};

export default React.memo(Button);
