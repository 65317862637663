import React from 'react';
import { Input as InputItem } from 'antd-mobile';
import { useController } from 'react-hook-form';
import ErrorMessage from 'src/components/error-message/ErrorMessage';
import styles from './styles.module.scss';
import { IInput } from './type';

const Input = React.forwardRef<HTMLInputElement, IInput>(
  ({ placeholder, type, name, ...props }, ref) => {
    const { field, fieldState } = useController({ ...props, name });
    return (
      <div className={styles.input}>
        <InputItem
          {...props}
          {...field}
          clearable
          placeholder={placeholder}
          type={type}
        />
        <ErrorMessage message={fieldState?.error?.message} />
      </div>
    );
  }
);

export default React.memo(Input);
