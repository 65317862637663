import {
  AnyAction,
  configureStore,
  Dispatch,
  Middleware,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { configureApi } from 'src/utils/api';
import reducer from './reducer';

const middlewareList: Middleware<{}, any, Dispatch<AnyAction>>[] = [];

// process.env.NODE_ENV
const isDev = false;

if (isDev) {
  middlewareList.push(logger);
}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    ...middlewareList,
  ],
});

configureApi(store);

export type RootState = ReturnType<typeof reducer>;

export default store;
