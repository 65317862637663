import React from 'react';
import { Provider } from 'react-redux';
import Auth from 'src/auth/Auth';
import Routes from 'src/pages';
import ErrorBoundary from 'src/pages/ErrorBoundary';
import store from 'src/store/initializeStore';
import 'src/components/components.scss';
import './App.scss';
// import VConsole from 'vconsole';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const vConsole = new VConsole();

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Provider store={store}>
          <Auth>
            <Routes />
          </Auth>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
