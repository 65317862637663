import React, { useEffect } from 'react';
import { NavBar } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/components';
import { isWeiXin } from 'src/utils/browser';
import styles from './styles.module.scss';

interface IHeader {
  title: string;
  hideIcon?: boolean;
}

const Header = ({ title, hideIcon }: IHeader) => {
  const history = useHistory();

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (isWeiXin()) {
    return null;
  }

  return (
    <div className={styles.header}>
      <NavBar
        className={styles.navBar}
        onBack={() => history?.go(-1)}
        backArrow={hideIcon ? null : <Icon name="left" />}
      >
        {title}
      </NavBar>
    </div>
  );
};

export default React.memo(Header);
