import React, { useState } from 'react';
import { ImageViewer } from 'antd-mobile';
import { IImageUploadItem } from './type';
import ImageUpload from './ImageUpload';
import Spacing from '../spacing/Spacing';

const ImageUploadItem = React.forwardRef<HTMLImageElement, IImageUploadItem>(
  ({ title, required, hint, exampleImage, ...props }, ref) => {
    const [visible, setVisible] = useState(false);

    return (
      <div className="component-item">
        <div className="component-item-title">
          <span>{title}</span>
          {required && <span className="component-required">*</span>}
          {exampleImage && (
            <>
              <span
                className="component-example"
                onClick={() => {
                  console.warn(111);
                  setVisible(true);
                }}
              >
                查看示例图
              </span>
              <ImageViewer
                visible={visible}
                image={exampleImage}
                onClose={() => setVisible(false)}
              />
            </>
          )}
        </div>
        {hint && (
          <div className="component-hint">
            <span>注：{hint}</span>
          </div>
        )}
        <Spacing height={8} />
        <ImageUpload {...props} />
      </div>
    );
  }
);

export default React.memo(ImageUploadItem);
