import axios from 'axios';
import api from 'src/utils/api';
export const getFile = () =>
  api.post('/cloudH5/getCommitmentLetter').then((res) => res.data);

export const getFileDetail = (url: string, headerMap: any) =>
  axios({
    method: 'get',
    url,
    headers: {
      'x-obs-nonce-str': headerMap['x-obs-nonce-str'], // 设置认证令牌
      'x-obs-signature-count': headerMap['x-obs-signature-count'] // 设置内容类型
    }
  }).then((res) => {
    return res.data;
  });

