import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const getParams = (params?: Record<string, string | number>) => {
  let p = '';
  if (params) {
    p = '?';
    const list = Object.keys(params);
    list.forEach((item: string, index: number) => {
      p += `${item}=${params[item]}`;
      if (index !== list.length - 1) {
        p += '&';
      }
    });
  }
  return p;
};

export const push = (url: string, params?: Record<string, string | number>) => {
  let p = getParams(params);
  history.push({ pathname: url, search: p });
};

export const replace = (
  url: string,
  params?: Record<string, string | number>
) => {
  let p = getParams(params);
  history.replace({ pathname: url, search: p });
};

export default history;
