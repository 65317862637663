import React from 'react';

interface ISpacing {
  height: number;
}

const Spacing = ({ height }: ISpacing) => {
  return <div style={{ height: `${height / 37.5}rem` }} />;
};

export default React.memo(Spacing);
