import { push } from 'src/utils/history';
import { ROUTE_PATH } from 'src/pages/router';

export const goProtocolFile = ({
  title,
  name,
  headerMap,
}: {
  title: string;
  name: string;
  headerMap: string;
}) => {
  push(ROUTE_PATH.PROTOCOL_FILE, { title, name, headerMap });
};
