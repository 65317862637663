import { Toast } from 'antd-mobile';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getKR } from './crypto';
import getHeaders from 'src/utils/signature';
import { getAuth, setAuth } from 'src/store/reducer/auth';
import { getUrlParams, removeUrlParams } from 'src/store/reducer/urlParams';

let store: any;

export const configureApi = (_store: any) => {
  store = _store;
};

// 网络请求失败的设置

let api = axios.create({
  baseURL: '/icpweb',
  timeout: 60000,
  headers: {},
});

const livenessApi = axios.create({
  baseURL: '',
  timeout: 60000,
  headers: {},
});

// 请求拦截
livenessApi.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const { url, data, method } = config;
    const headers = getHeaders({ url, params: data, method });
    Object.assign(config.headers, headers);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 返回拦截
livenessApi.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.status === 200) {
      const { code } = res.data;
      if (!code) {
        return res;
      }
      // if (res.data.code !== '0') { // 太多判断，先不校验
      // Toast.show({ content: res?.data?.message });// 自行处理
      // }
      return res.data;
    }
    return Promise.reject(res.data);
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 请求拦截
api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const { tenantId, loginName, source } = getUrlParams(state);
    const { token } = getAuth(state);
    const obj = token ? { token } : {};
    config.headers = {
      ...config.headers,
      tenantId,
      loginName,
      n: source,
      requestFrom: 6,
      ...obj,
      ...getKR(),
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 返回拦截
api.interceptors.response.use(
  (res) => {
    if (res.status !== 200) {
      Toast.show({ icon: 'fail', content: '请求失败' });
    }
    if (res.data.code === 4000 || res.data.code === 5118) {
      store.dispatch(
        setAuth({ token: '', code: res.data.code, message: res.data.message })
      );
      store.dispatch(removeUrlParams());
    }
    if (
      res.data.code !== 5999 &&
      res.data.code !== 2000 &&
      res.data.code !== 4000 &&
      res.data.message
    ) {
      Toast.show({ content: res?.data.message });
    }

    return res.data;
  },
  (err) => {
    if(err.message.includes('timeout')){
      Toast.show({ content: '网络超时' });
    }
    return Promise.reject(err);
  }
);

export { livenessApi };

export default api;
