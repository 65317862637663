import { combineReducers } from '@reduxjs/toolkit';
import form from './form';
import liveness from './liveness';
import auth from './auth';
import urlParams from './urlParams';
import captcha from './captcha';
import file from './file';
import cloudProtocol from './cloudProtocol';

export default combineReducers({
  form,
  liveness,
  auth,
  urlParams,
  captcha,
  file,
  cloudProtocol,
});
