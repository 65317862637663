/**
 * 用来保存file信息
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IState = Record<string, object>;

interface FileTs {
  file: File;
}

const initialState: IState = {
  file: {},
};

const liveness = createSlice({
  name: 'liveness',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<FileTs>) => {
      const { file } = action?.payload;
      state.file = file;
      return state;
    },
    remove: (state, action: PayloadAction<any>) => {
      state.file = {};
    },
  },
});

export const { save, remove } = liveness?.actions;

export default liveness.reducer;
